// import Raffle from '../components/earn/raffle'

import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Offerwall from "../components/earn/offerwall";
import Rewards from "../components/earn/rewards";
import Rp from "../components/all/rp"
const Earn = ({ user, settings, ws }) => {
  const [rewards, setRewards] = useState(false);

  return (
    <>
      {rewards && <Rewards setShow={setRewards} user={user} />}

      <div className="earn-container">
        <div className="title">
          <span className="blue">
            <FontAwesomeIcon icon={faHandHoldingDollar} />
          </span>
          <p>Earn</p>
        </div>

        <div className="free-container">
          <div className="discord">
            <p>
              Join our{" "}<span
                className="highlight"
                onClick={() =>
                  window.open(
                    "https://discord.gg/nJeMv7JUk7",
                    "_blank"
                  )
                }
              >
                Discord
              </span>{"!"}
            </p>
            <br />
          </div>
          {/* <Raffle/> */}
        </div>
        {/*<div className='free-container'>
                    <div className={'agvids '+ user?.country}>
                        <p><b>New!</b> AdGate now offer video rewards, 21 diamonds for 30 seconds! <span className='highlight' onClick={() => window.open('https://seek.gg/vc/oKmcrQ/users/'+user?.offer_id+'/offers/404918?wall_pos=1&source_type=adgate_rewards', '_blank')}>Click Here</span> to check it out!</p>
                    </div>
                 </div>*/}

        <div className="walls">
          <Offerwall
            user={user}
            disabled={!settings?.notik}
            bonus={settings?.notik_bonus && "🔥10%"}
            img="/walls/notik_logo.png"
            bg="#4d68e5"
            link={`https://notik.me/coins?api_key=q9nE29d8zq1SPu5AdtdX62YVmkKs1AaO&pub_id=k2Rg&app_id=9hXL7FM2R0&user_id=${user?.offer_id}`}
          />

          <Offerwall
            user={user}
            disabled={!settings?.adscend}
            bonus={settings?.adscend_bonus && "🔥10%"}
            img="/walls/adscend.svg"
            bg="white"
            link={`https://asmwall.com/adwall/publisher/116331/profile/19637?subid1=${user?.offer_id}`}
          />

          <Offerwall
            user={user}
            disabled={!settings?.ayet}
            bonus={settings?.ayet_bonus && "🔥10%"}
            img="/walls/ayetstudios.png"
            bg="#223548"
            link={`https://www.ayetstudios.com/offers/web_offerwall/14714?external_identifier=${user?.offer_id}`}
          />

          {/* <Offerwall
            user={user}
            disabled={!settings?.theorem}
            bonus={settings?.theorem_bonus && "🔥10%"}
            img="/walls/theoremreachLogo.svg"
            bg="#3c098d"
            link={`https://theoremreach.com/respondent_entry/direct?api_key=03f4554fdb7cec1a65c3c68b7cce&user_id=${user?.offer_id}`}
          /> */}
{/* 
          <Offerwall
            user={user}
            disabled={!settings?.earnwave}
            bonus={settings?.earnwave_bonus && '🔥10%'}
            img='/walls/earnwave.png'
            bg='#292f33'
            link={`https://wall.earnwave.io/nnnnnc?uid=${user?.offer_id}`}
          /> */}

          <Offerwall
            user={user}
            disabled={!settings?.adtowall}
            bonus={settings?.adtowall_bonus && '🔥10%'}
            img='/walls/adtowall.svg'
            bg='#F5F5FA'
            link={`https://adtowall.com/6180/${user?.offer_id}`}
          />

          <Offerwall
            user={user}
            disabled={!settings?.adgate}
            bonus={settings?.adgate_bonus && "🔥10%"}
            img="/walls/adgatemedia.png"
            bg="#46BBF9"
            link={`https://wall.adgaterewards.com/naiarmo/${user?.offer_id}`}
          />
          <Offerwall
            user={user}
            disabled={!settings?.timewall}
            bonus={settings?.timewall_bonus && "🔥10%"}
            img="/walls/timewall.png"
            bg="#4EB76E"
            link={`https://timewall.io/users/login?oid=68f6d9f251ce0b1d&uid=${user?.offer_id}`}
          />
          <Offerwall
            user={user}
            disabled={!settings?.lootably}
            bonus={settings?.lootably_bonus && "🔥10%"}
            img="/walls/lootably.png"
            bg="#30B0D5"
            link={`https://wall.lootably.com/?placementID=cloapn4uf023t01uxeb8v3dny&sid=${user?.offer_id}`}
          />
          <Offerwall
            user={user}
            disabled={!settings?.cheddartv}
            bonus={settings?.cheddartv_bonus && "🔥10%"}
            img="/walls/cheddar.svg"
            bg="#F9F9F9"
            link={`https://api.lootably.com/api/offerwall/redirect/offer/101-999?placementID=cloapn4uf023t01uxeb8v3dny&rawPublisherUserID=${user?.offer_id}`}
          />

          <Offerwall
            user={user}
            disabled={!settings?.cpx}
            bonus={settings?.cpx_bonus && "🔥10%"}
            img="/walls/cpxresearch.png"
            bg="#4E97FC"
            link={`https://offers.cpx-research.com/index.php?app_id=21240&ext_user_id=${user?.offer_id}`}
          />

          {/* <Offerwall
            user={user}
            disabled={!settings?.adgem}
            bonus={settings?.adgem_bonus && "🔥10%"}
            img="/walls/adgem.png"
            bg="#7359A4"
            link={`https://api.adgem.com/v1/wall?appid=23249&playerid=${user?.offer_id}`}
          /> */}

          <Offerwall
            user={user}
            disabled={!settings?.revu}
            bonus={settings?.revu_bonus && "🔥10%"}
            img="/walls/revenueuniverse.svg"
            bg="#FFFFFF"
            link={`https://publishers.revenueuniverse.com/wallresp/933/offers?uid=${user?.offer_id}`}
          />

          {/* <Offerwall
            user={user}
            disabled={!settings?.torox}
            bonus={settings?.torox_bonus && "🔥10%"}
            img="/walls/torox.png"
            bg="#DCB2FF"
            link={`https://www.torox.io/ifr/show/30055/${user?.offer_id}/14395`}
          /> */}

          {/* <Offerwall
            user={user}
            disabled={!settings?.inbrain}
            bonus={settings?.inbrain_bonus && "🔥10%"}
            img="/walls/inbrain.svg"
            bg="white"
            link={`https://www.surveyb.in/configuration?params=YjBIV1pxVHpKTnRuVjg1U3YrWDlIWFQvaVFHc3UxcEh1WklTRStzNCs1ZDhaVTdEanpKYlBVT1VBbnRwc1FjNHl0UHF2bXFWelRWNERzVSt4NjZ2VVFRekk2MEVUQjZ0V3lJL0twNHJkdW14RkR4ZUlMbEhRR3NvaWxPZm11ZHdYK0RoTkVkTk1rcGdlaUxUSmR5RGJDRjJFbThDZHFaS1NOcEdseXZFN2hrNWM4Zy9GcWZ1ZXlxNHJEMEhtNlBIejV1RVdPYUwrT3JFQjluejd3WlVJdz09&app_uid=${user?.offer_id}`}
          /> */}

          {/* <Offerwall
            user={user}
            //min_level={5}
            disabled={!settings?.bitlabs}
            bonus={settings?.bitlabs_bonus && "🔥10%"}
            img="/walls/bitlabs.svg"
            bg="#007BFF"
            link={`https://web.bitlabs.ai/?uid=${user?.offer_id}&token=567fbed6-4d62-47b0-b6ea-0c034b1e58e0`}
          /> */}

          {/* <Offerwall
            user={user}
            disabled={!settings?.monlix}
            bonus={settings?.monlix_bonus && "🔥10%"}
            img="/walls/monlix.svg"
            bg="#2ED47B"
            link={`https://offers.monlix.com/?appid=1606&userid=${user?.offer_id}`}
          /> */}

          {/* <Offerwall
            user={user}
            disabled={!settings?.ayet}
            bonus={settings?.polltastic_bonus && "🔥10%"}
            img="/walls/polltastic.png"
            bg="#dc4343"
            link={`https://surveys.ayet.io/?adSlot=13250&external_identifier=${user?.offer_id}`}
                />*/}



          <Offerwall
            user={user}
            disabled={!settings?.rewards}
            img="/walls/gnrewards.png"
            bg="#401501"
            link={null}
            setShow={setRewards}
          />
        </div>
        <Rp user={user} />
      </div>

      <style jsx>{`
        .earn-container {
          width: 100%;
          height: 100%;

          display: flex;
          flex-direction: column;

          padding: 50px 200px;
          gap: 25px;

          overflow-x: hidden;
          overflow-y: auto;
        }

        .earn-container::-webkit-scrollbar {
          display: none;
        }

        .title {
          font-weight: 500;
          font-size: 24px;

          gap: 10px;
          display: flex;
          align-items: center;
        }

        .title p {
          margin: unset;
        }

        .blue {
          color: var(--highlight-color);
        }

        .free-container {
          display: flex;
          height: fit-content;
          gap: 15px;
        }

        .discord {
          background: var(--secondary-background);
          border-radius: 12px;
          height: 100px;
          max-height: 100px;
          width: 100%;
          min-width: 265px;

          justify-content: center;
          display: flex;
          align-items: center;

          color: var(--highlight-color);
          font-weight: 600;
          font-size: 16px;
          text-align: center;
        }

        .discord .highlight {
          color: #d54408;
          cursor: pointer;
        }

        .walls {
          background: var(--dark-background);
          width: 100%;
          border-radius: 12px;

          display: inline-grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          grid-gap: 15px;
          padding: 15px;
        }

        .agvids {
          display: none;
        }

        .agvids.US {
          display: flex !important;
          background: #21294d;
          border-radius: 12px;
          height: 100px;
          max-height: 100px;
          width: 100%;
          min-width: 265px;

          justify-content: center;
          align-items: center;

          color: #4caeff;
          font-weight: 600;
          font-size: 16px;
          text-align: center;
        }

        .agvids .highlight {
          color: #7288dc;
          cursor: pointer;
        }

        @media only screen and (max-width: 1800px) {
          .free-container {
            flex-direction: column;
            height: fit-content;
          }
        }

        @media only screen and (max-width: 1100px) {
          .earn-container {
            padding: 50px 100px;
          }
        }

        @media only screen and (max-width: 870px) {
          .earn-container {
            padding: 50px;
          }
        }

        @media only screen and (max-width: 870px) {
          .earn-container {
            padding: 50px;
          }
        }

        @media only screen and (max-width: 400px) {
          .earn-container {
            padding: 50px 25px;
          }
        }

        @media only screen and (max-width: 290px) {
          .earn-container {
            padding: 50px 0px;
          }
        }
      `}</style>
    </>
  );
};

export default Earn;
