import { faGem } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { backendRequestAndNotification } from '../../api/index'

const Giftcards = ({ img, bg, warning, fee, amounts, type }) => {

    const [card, setCard] = useState(0)
    const cardToCost = (num = card) => {
        if (amounts) { return amounts[num] }

        switch (num) {
            case 0:
                return 5000
            case 1:
                return 10000
            case 2:
                return 15000
            case 3:
                return 25000
            default:
                return 0
        }
    }

    const getFee = () => {
        return type === 'paypal' ? 500 : cardToCost() * fee;
    }

    return (
        <>
            <div className='cashout-container'>
                <div className='cards'>
                    <div className={'card ' + (card === 0 ? 'active' : '')} onClick={() => setCard(0)}>
                        <img src={img} alt='' />
                        <p className='cost'>${cardToCost(0) / 1000}</p>
                    </div>

                    <div className={'card ' + (card === 1 ? 'active' : '')} onClick={() => setCard(1)}>
                        <img src={img} alt='' />
                        <p className='cost'>${cardToCost(1) / 1000}</p>
                    </div>

                    <div className={'card ' + (card === 2 ? 'active' : '')} onClick={() => setCard(2)}>
                        <img src={img} alt='' />
                        <p className='cost'>${cardToCost(2) / 1000}</p>
                    </div>

                    <div className={'card ' + (card === 3 ? 'active' : '')} onClick={() => setCard(3)}>
                        <img src={img} alt='' />
                        <p className='cost'>${cardToCost(3) / 1000}</p>
                    </div>
                </div>

                <div className='bottom'>
                    <div>
                        <p className='info'>
                            <span className='blue'>Fee:</span>
                            <span className='right'>{getFee()} <FontAwesomeIcon icon={faGem} /></span>
                        </p>
                        <p className='info bg'>
                            <span className='blue'>Total Cost:</span>
                            <span className='right'>{cardToCost() + getFee()} <FontAwesomeIcon icon={faGem}/></span>
                        </p>
                    </div>

                    <div className='purchase' onClick={() => {
                        backendRequestAndNotification(`/withdraw/gc`, 'POST', { type: type, amount: parseInt(cardToCost(card)) })
                    }}>
                        <p>Purchase</p>
                    </div>
                </div>
            </div>
            <div className="methods"><p>{type == 'visa' && 'Please note: for Visa withdrawals, you will receive a code in the withdrawals section of your profile page under Payment Reference #. You will need to redeem this code at https://myprepaidcenter.com/redeem.'}</p></div>
            <style jsx>
                {`
                    .cashout-container {
                        min-height: 250px;
                        max-height: 250px;
                        width: 100%;
                        background-color: var(--dark-background);
                        border-radius: 8px;
                        padding: 15px;
                        font-weight: 600;

                        overflow: hidden;
                    }

                    .cards {
                        width: 100%;
                        height: 140px;

                        display: inline-grid;
                        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
                        grid-gap: 15px;
                    }

                    .card {
                        height: 140px;
                        background-color: ${bg};

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 6px;
                        position: relative;
                        transition: transform .5s;

                        cursor: pointer;
                    }

                    .card img {
                        width: 140px;
                    }

                    .card:hover {
                        transform: scale(0.98);
                    }

                    .cost {
                        position: absolute;
                        top: 15px;
                        right: 15px;
                        margin: unset;
                        color: ${bg === 'white' ? 'black' : 'white'};
                    }

                    .active {
                        outline: 4px solid rgb(77, 169, 248);
                    }

                    .bottom {
                        width: 100%;
                        display: flex;
                        align-items: center;
                    }

                    .info {
                        width: 280px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        padding: 0px 10px;
                        margin: 3px 0px;
                    }

                    .info.bg {
                        background-color: var(--secondary-background-active);
                        border-radius: 6px;
                        box-shadow: 0 3px 10px rgb(0,0,0,.2);
                    }

                    .right {
                        margin-left: auto;
                    }

                    .blue {
                        color: var(--highlight-color);
                    }

                    .purchase {
                        margin-left: auto;
                        height: 50px;
                        width: 170px;
                        background-color: var(--secondary-background-active);
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #ef7d4d;
                        transition: all .2s;
                        cursor: pointer;
                    }

                    .purchase:hover {
                        background-color: var(--secondary-background-active)
                    }

                    @media only screen and (max-width: 950px) {
                        .cashout-container {
                            flex-direction: column;
                            min-height: unset;
                            max-height: unset;
                            height: fit-content;
                            align-items: center;
                            gap: 25px;
                        }

                        .cards {
                            height: fit-content;
                        }

                        .bottom {
                            height: fit-content;
                        }
                    }

                    @media only screen and (max-width: 625px) {
                        .bottom {
                            flex-direction: column;
                            align-items: center;
                            gap: 10px;
                        }

                        .purchase {
                            width: 100%;
                        }
                    }
                `}
            </style>
        </>
    )
}

export default Giftcards